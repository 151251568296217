// export const WEBSITE_URL = "http://127.0.0.1/swiggy-laravel-react";
// export const WEBSITE_URL = "https://mywebsite.com";
// export const WEBSITE_URL = "https://demo.foodomaa.com";

// FOR LIVE
// export const WEBSITE_URL = "https://salasell.online";
// export const WEBSITE_ASSET_URL = "https://salasell.online";
// export const WEBSITE_DOMAIN_NAME = window.location.hostname;

// FOR DEV
// export const WEBSITE_DOMAIN_NAME = window.location.hostname;
// export const WEBSITE_URL = "https://salasellonline.demoproject.info";
// export const WEBSITE_ASSET_URL = "https://salasellonline.demoproject.info";

// FOR magefai.online
export const WEBSITE_DOMAIN_NAME = window.location.hostname;
// export const WEBSITE_URL = window.location.hostname;
// export const WEBSITE_ASSET_URL = window.location.hostname;


export const WEBSITE_URL = "https://magefai.online";
export const WEBSITE_ASSET_URL = "https://magefai.online";

// export const WEBSITE_URL = "http://localhost/mohammad/foodoma-git/foodomaback";
// export const WEBSITE_ASSET_URL = "http://localhost/mohammad/foodoma-git/foodomaback";

// FOR LOCAL
// export const WEBSITE_DOMAIN_NAME = "salasellonline.demoproject.info";
// export const WEBSITE_URL = "https://salasellonline.demoproject.info";
// export const WEBSITE_ASSET_URL = "https://salasellonline.demoproject.info";

// FOR ne-feeseh-kw.com
// export const WEBSITE_URL = "https://salasell.online";
// export const WEBSITE_ASSET_URL = "https://salasell.online";
// export const WEBSITE_DOMAIN_NAME = "nafeeseh-kw.com";

// export const WEBSITE_DOMAIN_NAME = "caffe.salasell.online";//window.location.hostname;



// export const WEBSITE_URL = "";

// export const WEBSITE_DOMAIN_NAME = "salasellonline.demoproject.info";
// export const WEBSITE_URL = "https://salasellonline.demoproject.info";
// export const WEBSITE_ASSET_URL = "https://salasellonline.demoproject.info";
// export const WEBSITE_DOMAIN_NAME = "salasellonline.demoproject.info"
// export const WEBSITE_DOMAIN_NAME = window.location.hostname;//window.location.hostname;
